// extracted by mini-css-extract-plugin
export var container = "AboutUs-module--container--3aKVK";
export var content = "AboutUs-module--content--Hvdl8";
export var title = "AboutUs-module--title--2CSPJ";
export var titleMobile = "AboutUs-module--titleMobile--Cg27M";
export var section = "AboutUs-module--section--zdwtJ";
export var sectionInfo = "AboutUs-module--sectionInfo--23hT1";
export var sectionTextTitle = "AboutUs-module--sectionTextTitle--3cbAw";
export var sectionText = "AboutUs-module--sectionText--9tvXw";
export var buttonToTop = "AboutUs-module--buttonToTop--3KAGl";
export var buttonToTopHidden = "AboutUs-module--buttonToTopHidden--1BmWr";