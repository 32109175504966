import React, { useEffect, useState } from "react";
import { graphql } from 'gatsby'
import { trackingGA } from '@merchx-v2/helpers'
import { useSiteMetadata } from 'hooks/use-site-metadata'
import { Footer, Header, FollowUs, SEO } from "components";
import * as styles from "./AboutUs.module.scss";


const AboutUs = ({ data }) => {
  const { landing } = useSiteMetadata()

  const { SEO_TITLE, SEO_DESCRIPTION, SEO_KEYWORDS } = data.markdownRemark?.frontmatter || {}

  const campaignName = landing.CAMPAIGN.name;

  const [showButtonClass, setShowButtonClass] = useState("buttonToTopHidden");

  const onScroll = (e) => {
    if (window.pageYOffset > 100) {
      setShowButtonClass("buttonToTop");
    } else {
      setShowButtonClass("buttonToTopHidden");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    trackingGA()
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className={styles.container}>
      <SEO
        title={SEO_TITLE}
        description={SEO_DESCRIPTION}
        keywords={SEO_KEYWORDS}
        pathname='/about-us'
      />
      <Header />

      <div className={styles.content}>
        <div className={styles.title}>About Us</div>
        <div className={styles.titleMobile}>About {campaignName}</div>

        <section className={styles.section}>
          <div className={styles.sectionInfo}>
            <p className={styles.sectionTextTitle}>Our Values</p>
            <p className={styles.sectionText}>
              {campaignName} was built around the idea that people should
              express what they care about through their fashion.
            </p>
            <p className={styles.sectionText}>
              Whether it’s your pet, family, hobby, or taste in music, you
              should be able to proudly show the world what you’re passionate
              about.
            </p>
            <p className={styles.sectionText}>
              We want conversations to be started between fellow passionate
              people. Friendships to be made. Why wear a brand name when you can
              wear something you love and care about?
            </p>
            <p className={styles.sectionTextTitle}>Our Mission</p>
            <p className={styles.sectionText}>
              Our goal is to create a world where wearing your passion is the
              norm. And to accomplish this, we built our business around that
              vision.
            </p>
            <p className={styles.sectionText}>
              First, we try to make sure there’s a product for everyone. With
              hundreds of designs made by real, passionate people, we’re certain
              you’ll find something perfect for you.
            </p>
            <p className={styles.sectionText}>
              Second, we try our best to make our products more widely
              available. With no middle man, we’re able to keep our high quality
              products affordable. That means we handle the products, the
              printing, and the shipping, all right here in the USA.
            </p>
            <p className={styles.sectionText}>
              Lastly, we put customer satisfaction above all. We quality check
              each order by hand and have a large, highly experienced customer
              service team that is available for you 24/7.
            </p>
            <p className={styles.sectionText}>
              Help us create this world. Join us in making the world a more
              passionate place.
            </p>
          </div>
        </section>

        <FollowUs />
        <button
          className={styles[showButtonClass]}
          onClick={(e) => window.scroll(0, 0)}
        >
          Back to top
        </button>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;

export const query = graphql`
  query AboutUsPage {
    markdownRemark(fields: {slug: {eq: "/about-us/"}}) {
      frontmatter {
        SEO_TITLE
        SEO_DESCRIPTION
        SEO_KEYWORDS
      }
    }
  }
`
